.DataTable .p-paginator .p-paginator-current {
    margin-left: auto;
}

.DataTable .p-progressbar {
    height: .5rem;
    background-color: #D8DADC;
}

.DataTable .p-progressbar .p-progressbar-value {
    background-color: #607D8B;
}

.DataTable .table-header {
    display: flex;
    justify-content: space-between;
}

.DataTable .p-datepicker {
    min-width: 25rem;
}

.DataTable .p-datepicker td {
    font-weight: 400;
}

.DataTable .p-datatable.p-datatable-customers .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
}

.DataTable .p-datatable.p-datatable-customers .p-paginator {
    padding: 1rem;
}

.DataTable .p-datatable.p-datatable-customers .p-datatable-thead > tr > th {
    text-align: left;
}

.DataTable .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
    cursor: auto;
}

.DataTable .p-datatable.p-datatable-customers .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
}


@media screen and (max-width: 960px) {
    .DataTable .p-datatable.p-datatable-customers .p-datatable-thead > tr > th,
    .DataTable .p-datatable.p-datatable-customers .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .DataTable .p-datatable.p-datatable-customers .p-datatable-tbody > tr {
        border-bottom: 1px solid var(--layer-2);
    }

    .DataTable .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        border: 0 none !important;
        width: 100% !important;
        float: left;
        clear: left;
        border: 0 none;
    }

    .DataTable .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
        padding: .4rem;
        min-width: 30%;
        display: inline-block;
        margin: -.4rem 1rem -.4rem -.4rem;
        font-weight: bold;
    }

    .DataTable .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td .p-progressbar {
        margin-top: .5rem;
    }
}

    .p-column-filter{
        width: 125px;
    }