.toolbarLogin{
    align-items: center;
    background:  #0053af;
    height: 60px;
    width: 100%;
}

.authBox{
    width: 100%;
    overflow: hidden;
    margin: 70px auto 30px;
}

.image{
    width: 140px;
    height: 140px;
    float: left;
    position: relative;
    margin-top: 60px;
    margin-left: 25rem;
}

.imageAuth{
    background-image: url("../Img/logex.png");
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.imageAuth{
    font-size: 28px;
    position: absolute;
    color: #ffffff;
}

.box{
    width: 420px;
    height: 415px;
    margin: auto auto;
}

.subtitleAuth{
    font-family: Orienta;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    text-align: left;
    color:#0053af;
}

.titleAuth{
    font-family: Orienta;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    text-align: left;
    font-weight: bold;
    margin-bottom: 28px;
    color:#0053af;
}

@media screen and (max-width: 1500px){
    .image{
        margin-left: 14rem;
    }
}

@media screen and (max-width: 1200px){
    .image{
        margin-left: 6rem;
    }
}

@media screen and (max-width: 900px){
    .image{
        margin-left: 0rem;
    }
    .box{
        width: 320px;
    }
}


