.toolbar{
    display: flex;
    align-items: center;
    background:  #0053af;
    width: 180%;    
}

.toolbar_navigation{
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0.8rem 1rem;
    width: 100%;
}

.toolbar_logo{
    margin-left: 1.5rem;
}

.toolbar_logo a{
    color: #ffffff;
    text-decoration: none;
    font-size: 1.5rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.spacer{
    flex: 1;
}

.toolbar_navigation_items ul{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.toolbar_navigation_items li{
    padding: 0 0.5rem;
}

.toolbar_navigation_items a{
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.toolbar_navigation_items a:hover,
.toolbar_navigation_items a:active{
    color: #ffffff;
}