ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.contenido{    
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-list-li-sp{
    border-radius: 3px;
    margin: 5px;
    padding: 1.2vh;
    border: 1px solid rgba(0, 0, 0, 0.082);
    cursor: pointer;
}

.content-list-li-p{
    border-radius: 3px;
    margin: 5px;
    padding: 1.2vh;
    border: 1px solid rgba(0, 0, 0, 0.082);
    cursor: pointer;
    background: #0052afaf;
    color: #FFF;
}

.content-list-contenido{
    padding: 1.2vh 1.2vh 1.2vh 1.2vh;
}
